import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {PermissionsI} from "../../../../infrastructure/interfaces/permissions.interface";

@Component({
  selector: 'app-permissions-field',
  templateUrl: './permissions-field.component.html',
  styleUrls: ['./permissions-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PermissionsFieldComponent),
      multi: true,
    },
  ],
})
export class PermissionsFieldComponent implements ControlValueAccessor{
  @Input() language: any = {}

  value: any = {
    list: false,
    read: false,
    write: false,
    delete: false
  };

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(obj: PermissionsI): void {
    this.value = obj;
  }

  togglePermission(name: string) {
    this.value[name] = !this.value[name]
    this.onChange(this.value)
    this.onTouch(this.value)
  }

}
