<div class="position-relative w-100 h-100 d-flex flex-column justify-content-start align-items-center overflow-hidden">
  <!-- Search Users and Groups -->
  <div class="w-100 px-3 pt-3 pb-2 d-flex align-items-center border-neutral-color-c">
    <div class="w-100 position-relative">
      <input [(ngModel)]="selected"
             [typeahead]="dataSource"
             [typeaheadAsync]="true"
             [typeaheadOptionField]="displayField"
             typeaheadGroupField="type"
             [typeaheadScrollable]="true"
             [typeaheadMinLength]="2"
             [typeaheadOptionsInScrollableView]="10"
             [optionsListTemplate]="typeaheadTemplate"
             [adaptivePosition]="true"
             class="search-user-input">
    </div>
  </div>

  <!-- Selected Users and Groups -->
  <div
    *ngIf="selectedObjectsUser.length > 0 || selectedObjectsGroup.length > 0"
    class="flex-grow-1 w-100 overflow-y-auto ps-2 pe-3 pb-2 d-flex flex-column gap-1"
  >

    <!-- Users -->
    <ng-container *ngIf="selectedObjectsUser.length > 0">
      <div class="w-100 d-flex justify-content-start align-items-center ps-2">
        <span class="text-sub-title text-complementary-color-a fw-bolder">
          {{language?.user || "user" | titlecase}}s
        </span>
      </div>
      <app-security-user
        *ngFor="let selectedObject of selectedObjectsUser"
        [displayField]="displayField"
        [selectedObject]="selectedObject"
        [language]="language"
        (permissionsChanged)="changeObjectPermissions($event)"
        (permissionsRemoved)="removeObjectPermissions($event)"
      ></app-security-user>
    </ng-container>


    <ng-container *ngIf="selectedObjectsGroup.length > 0">
      <div class="border-top border-neutral-color-c"></div>

      <!-- Groups -->
      <div class="w-100 d-flex justify-content-start align-items-center ps-2">
        <span class="text-sub-title text-complementary-color-a fw-bolder">
          {{language?.group || 'group' | titlecase}}s
        </span>
      </div>
      <app-security-user
        *ngFor="let selectedObject of selectedObjectsGroup"
        [displayField]="displayField"
        [selectedObject]="selectedObject"
        [language]="language"
        (permissionsChanged)="changeObjectPermissions($event)"
        (permissionsRemoved)="removeObjectPermissions($event)"
      ></app-security-user>
    </ng-container>

  </div>

  <!-- Does not exist User and Groups -->
  <div
    class="flex-grow-1 w-100 overflow-y-auto ps-2 pe-3 pb-2 d-flex flex-column gap-1 justify-content-center"
    *ngIf="selectedObjectsUser.length === 0 && selectedObjectsGroup.length === 0"
  >
    <div class="w-100 d-flex justify-content-center">
      <img class="" src="assets/img/arrow.svg" style="width: 50px; transform: rotate(-30deg) translate(35px, 20px)" />
    </div>
    <div class="w-100 d-flex justify-content-center">
      <img class="empty-icon" src="assets/img/icon-roles-grey.svg" />
    </div>
    <div class="w-100 d-flex justify-content-center">
      <span class="text-normal text-neutral-color-h text-center">
        {{language?.noSecurityData || 'There are no users or groups to display'}}<br>
        {{language?.selectSecurityData || 'Please select one from the search engine'}}
      </span>
    </div>
  </div>

  <!--Loading-->
  <div *ngIf="loading" class="position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center">
    <div class="spinner-border text-primary" style="width: 60px; height: 60px" role="status">
      <span class="visually-hidden">{{language?.loading || 'loading' | titlecase}}...</span>
    </div>
  </div>
</div>




<ng-template #typeaheadTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
  <div class="d-flex w-100 flex-column">
    <div
      class="w-100 d-flex align-items-center px-1"
      [class.typeahead-header]="match.header"
      *ngFor="let match of matches"
    >
      <!-- Grouping header -->
      <ng-container *ngIf="match.header">
        <div class="text-sub-title fw-bold px-1" (click)="$event.stopPropagation()">
          {{language?.[match.value] || match.value | titlecase}}s
        </div>
      </ng-container>

      <!-- Match data -->
      <ng-container *ngIf="!match.header">
        <div
          class="w-100 d-flex align-items-center py-1 px-1 rounded-2 typeahead-item"
          (click)="selectUserGroup(match, $event)"
        >
          <!-- Picture -->
          <div class="avatar">
            <div
              [class.avatar-picture]="match.item?.[pictureField]"
              [class.avatar-text]="!match.item?.[pictureField]"
              [ngStyle]="{'background-image': match.item?.[pictureField] ? 'url(' + match.item?.[pictureField] + ')' : 'unset'}"
            >
              <span *ngIf="!match.item?.[pictureField]" style="font-size: 12px">{{match.item?.[displayField] | avatarText}}</span>
            </div>
          </div>

          <!-- Data -->
          <div class="d-flex flex-column flex-grow-1 ps-2">
            <!-- Name -->
            <div>
              <span
                class="text-mini truncate truncate-1"
              >
                {{match.item?.[displayField]}}
              </span>
            </div>
            <!-- users -->
            <div *ngIf="match.item?.[usersField]">
              <span class="text-mini truncate truncate-1">{{language.user || 'user' | titlecase}}s: {{match.item?.[usersField] || 0}}</span>
            </div>
          </div>

          <!-- Hover Layer -->
          <div class="typeahead-item-layer"></div>
        </div>
      </ng-container>

    </div>
  </div>
</ng-template>
