import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TaskPermissionsObjectI} from "../../../../infrastructure/interfaces/task.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, Subject, takeUntil, throttleTime} from "rxjs";
import {PermissionsI} from "../../../../infrastructure/interfaces/permissions.interface";

@Component({
  selector: 'app-security-user',
  templateUrl: './security-user.component.html',
  styleUrls: ['./security-user.component.scss']
})
export class SecurityUserComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  @Input() selectedObject!: any;
  @Input() displayField: string = "name";
  @Input() keyField: string = "username"
  @Input() typeField: string = "type"
  @Input() pictureField: string = "picture"
  @Input() language: any = {}

  @Output() permissionsChanged: EventEmitter<TaskPermissionsObjectI> = new EventEmitter<TaskPermissionsObjectI>()
  @Output() permissionsRemoved: EventEmitter<TaskPermissionsObjectI> = new EventEmitter<TaskPermissionsObjectI>()

  form: FormGroup

  constructor(
    fb: FormBuilder
  ) {
    this.form = fb.group({
      permissions: {list: false, read: false, write: false, delete: false}
    })
  }

  ngOnInit() {
    this.form.patchValue({
      permissions: this.selectedObject.permissions
    }, {onlySelf: true, emitEvent: false});

    this.permissions?.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      debounceTime(700),
      throttleTime(700)
    ).subscribe((value) => {
      this.permissionsChanged.emit({
        object: {...this.selectedObject.object},
        permissions: value
      });
    })
  }

  removeObject(item: TaskPermissionsObjectI){
    this.permissionsRemoved.emit(item);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get permissions() {
    return this.form.get('permissions');
  }
}
