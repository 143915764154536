<div class="d-flex justify-content-center align-items-center px-2 py-1 object-item">
  <!-- Picture -->
  <div class="avatar">
    <div
      [class.avatar-picture]="selectedObject?.object?.[pictureField]"
      [class.avatar-text]="!selectedObject?.object?.[pictureField]"
      [ngStyle]="{'background-image': selectedObject?.object?.[pictureField] ? 'url(' + selectedObject?.object?.[pictureField] + ')' : 'unset'}"
    >
      <span class="text-normal" *ngIf="!selectedObject?.object?.[pictureField]">{{selectedObject?.object?.[displayField] | avatarText}}</span>
    </div>
  </div>

  <!-- Object Data -->
  <div class="flex-grow-1 d-flex justify-content-center align-items-center flex-column ps-2">
    <!-- Object Name -->
    <div
      class="w-100 d-flex align-items-start"
    >
      <span class="text-normal truncate truncate-1">{{selectedObject?.object?.[displayField]}}</span>
    </div>
    <!-- Object Permissions -->
    <div
      class="w-100 pb-2"
      [formGroup]="form"
    >
      <app-permissions-field [language]="language" formControlName="permissions"></app-permissions-field>
    </div>
  </div>

  <!-- Object Remove -->
  <div class="pointer" (click)="removeObject(selectedObject)">
    <span
      class="material-symbols-outlined text-title text-danger"
      tooltip="{{language?.remove || 'remove' | titlecase}}"
      containerClass="text-mini fw-bold mb-1"
    >
      delete
    </span>
  </div>


</div>
