import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {Observable, Subject, takeUntil} from "rxjs";
import {ProcessFilterType, ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {
  selectDetailsProcessResult,
  selectLoadingDetailProcessResult,
  selectLoadingProcessResultMap,
  selectLoadingProcessResultPermissions,
  selectProcessResultFilter,
  selectProcessResultGlobalSearch,
  selectProcessResultMap,
  selectProcessResultPaginator,
  selectResultDetailsTab,
  selectShowFilterBar,
  selectShowRightBar,
  selectViewComponentProcessResult
} from "../../../infrastructure/store/process-result/process-result.selector";
import {
  ProcessResultFilterI,
  ProcessResultI,
  ProcessResultPaginatorI,
  ViewComponentType
} from "../../../infrastructure/interfaces/process-result.interface";
import {DetailTabs, TaskPermissionsObjectI,} from "../../../infrastructure/interfaces/task.interface";
import {Router} from "@angular/router";
import {
  loadProcessResultList,
  removeProcessResultPermissions,
  setDetailProcessResult,
  setLoadingResult,
  setProcessResultFilter,
  setResultDetailsTab,
  setShowFilterBar,
  setShowRightBar,
  setViewComponentProcessResult,
  updateProcessResultPermissions,
  updateProcessResultPermissionsSuccess
} from "../../../infrastructure/store/process-result/process-result.actions";
import {
  initialProcessResultPaginator,
  initialProcessStatusFilter,
  itemsPerPageList
} from "../../../infrastructure/store/process-result/state";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {LocalStorageService} from "../../../infrastructure/services/local-storage.service";
import {selectPermissionsObjects} from "../../../infrastructure/store/permissions/permissions.selector";
import {PermissionsObjectI} from "../../../infrastructure/interfaces/permissions.interface";
import {loadMapProcesslList} from '../../../infrastructure/store/map/map.action';
import {selectActiveViews} from "../../../infrastructure/store/config/config.selector";
import {TranslateService} from "@mangosoft/mango-layout";


@Component({
  selector: 'app-status-board',
  templateUrl: './status-board.component.html',
  styleUrls: ['./status-board.component.scss']
})
export class StatusBoardComponent implements OnInit, OnDestroy{

  destroy$ = new Subject<void>();

  math = Math;

  viewComponentType = ViewComponentType;

  detailTabs = DetailTabs;

  processFilterType = ProcessFilterType;

  showFilterBar: boolean = false;
  showRightBar: boolean = false;

  viewComponent: ViewComponentType = ViewComponentType.table;

  // todo: fix in interfaces
  itemPerPageList = itemsPerPageList;

  selectedProcess: ProcessI | null = null;

  resultPaginator: ProcessResultPaginatorI = initialProcessResultPaginator

  filterForm!: FormGroup;

  dynamicFilterForm!: FormGroup;
  dynamicFilterFormActive: any;

  processResultFilter: ProcessResultFilterI = initialProcessStatusFilter;
  globalSearch: string = '';

  processResultStatusFilterSelectAll = new Subject<boolean>();
  processResultStatusFilterClearAll = new Subject<boolean>();
  processStatusFilterCollapse = true;

  processResultTaskFilterSelectAll = new Subject<boolean>();
  processResultTaskFilterClearAll = new Subject<boolean>();
  processStateFilterCollapse = true;

  permissionObjects: Observable<PermissionsObjectI[]>;
  selectedTab: DetailTabs = DetailTabs.security;
  detailsProcessResult: ProcessResultI | null = null;
  detailsProcessResultPermissions: TaskPermissionsObjectI[] = [];
  loadingProcessResultPermissions: boolean = false;
  loadingDetailProcessResult: boolean = false;

  processResultMapLoaded: number = 0;
  processResultMapTotal: number = 0;
  processResultMapValid: number = 0;
  loadingProcessResultMap: boolean = false;

  ui_active_views: ViewComponentType[] = [];

  language!: any;

  constructor(
    private store: Store,
    private router: Router,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService
  ) {
    this.translateService
      .init("process-manager")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.language = data
      });

    this.permissionObjects = this.store.select(selectPermissionsObjects);
  }

  ngOnInit() {
    this.store.dispatch(loadMapProcesslList());

    // Listen for selected process changes
    this.store.select(selectSelectedProcess).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      if (value) {
        this.selectedProcess = value;
        this.buildFilterForm(value);
        this.buildDynamicFilterForm(value);
      }
    })

    // Listen for result paginator changes
    this.store.select(selectProcessResultPaginator).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.resultPaginator = value;
    })

    // Listen for Details Task changes
    this.store.select(selectDetailsProcessResult).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.detailsProcessResult = value;
      this.detailsProcessResultPermissions = [];
      if (this.detailsProcessResult){
        this.detailsProcessResultPermissions = this.detailsProcessResult.acl.objects.map((value) => {
          return {
            object: {
              ...value
            },
            permissions: {
              list: (this.detailsProcessResult?.acl.list || []).filter((val) => value.username === val).length > 0,
              read: (this.detailsProcessResult?.acl.read || []).filter((val) => value.username === val).length > 0,
              write: (this.detailsProcessResult?.acl.write || []).filter((val) => value.username === val).length > 0,
              delete: (this.detailsProcessResult?.acl.delete || []).filter((val) => value.username === val).length > 0,
            }
          }
        })
      }
    })

    // Listen for loading process result permissions
    this.store.select(selectLoadingProcessResultPermissions).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingProcessResultPermissions = value;
    })

    // Listen for show filter bar changes
    this.store.select(selectShowFilterBar).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.showFilterBar = value
    })

    // Listen for show right bar changes
    this.store.select(selectShowRightBar).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.showRightBar = value
    })

    // Listen for detail tab changes
    this.store.select(selectResultDetailsTab).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.selectedTab = value
    })

    // Listen for view component changes
    this.store.select(selectViewComponentProcessResult).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.viewComponent = value
    })

    //listen for global search changes
    this.store.select(selectProcessResultGlobalSearch).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.globalSearch = value || '';
    })

    //listen for global search changes
    this.store.select(selectLoadingDetailProcessResult).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingDetailProcessResult = value;
    })

    // listen for loading process result map changes
    this.store.select(selectLoadingProcessResultMap).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingProcessResultMap = value
    })

    // listen for process result map changes
    this.store.select(selectProcessResultMap).pipe(
      takeUntil(this.destroy$)
    ).subscribe((processResultMap) => {
      this.processResultMapTotal = processResultMap.count;
      this.processResultMapLoaded = processResultMap.loaded;
      this.processResultMapValid = processResultMap.valid
    })

    // listen for active views config changes
    this.store.select(selectActiveViews).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.ui_active_views = value;
    })
  }

  buildFilterForm(process: ProcessI) {
    this.filterForm = this.fb.group({
      itemPerPage: this.localStorageService.getItemPerPage()
    })

    // Listen for items per page changes
    this.itemPerPage?.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      // Sync between movil and desktop fields
      this.itemPerPage?.patchValue(value, {onlySelf: true, emitEvent: false});

      this.localStorageService.setItemPerPage(value);

      if (this.selectedProcess){
        this.store.dispatch(setLoadingResult({status: true}));

        const {bookmarked:_, process_status:__, tasks:___, ...filter} = this.processResultFilter

        this.store.dispatch(loadProcessResultList({
          processId: this.selectedProcess?.id,
          limit: value,
          page: 1,
          bookmarked: this.processResultFilter.bookmarked,
          process_status: this.processResultFilter.process_status,
          tasks: this.processResultFilter.tasks,
          filter: filter,
          globalSearch: this.globalSearch
        }))
      }
    })
  }

  buildDefaultFilter(processModel: ProcessI){
    let defaultFilter: any = {};

    // Initialize dynamic default values
    processModel.process_filters.forEach((filter) => {
      let default_value = filter.default || null;
      if (filter.type === ProcessFilterType.date && default_value === 'today'){
        default_value = new Date().toISOString().split("T")[0]
      }
      defaultFilter[filter.field] = default_value
    })

    // Update with fixed filters
    defaultFilter = {
      ...defaultFilter,
      process_status: processModel.process_states.states_list
        .filter((k) => k.selected)
        .map((k) => k.key),
      tasks: processModel.tasks
        .filter((v)=>v?.selected || false)
        .map((v)=>v.id)
    }

    return defaultFilter;
  }


  buildDynamicFilterForm(process: ProcessI) {
    const group: any = {
      bookmarked: new FormControl(false),
      process_status: new FormControl([]),
      tasks: new FormControl([])
    }

    // Generate dynamic fields from process model
    process.process_filters.forEach((filter) => {
      group[filter.field] = new FormControl()
    })
    this.dynamicFilterForm = new FormGroup(group);

    // Listen for process result filter changes
    this.store.select(selectProcessResultFilter).pipe(
      takeUntil(this.destroy$),
    ).subscribe((processResultFilter) => {
      this.processResultFilter = processResultFilter;

      if (this.processResultFilter.isInitial) {
        this.dynamicFilterForm.patchValue(this.buildDefaultFilter(process), {emitEvent: false, onlySelf: true})
      } else {
        this.dynamicFilterForm.patchValue(processResultFilter, {emitEvent: false, onlySelf: true});
      }

      //
      // if (this.processResultFilter.process_status.length === 0){
      //   //Initialize process status
      //   this.process_status?.patchValue(
      //     process.process_states.states_list
      //       .filter((v)=>v.selected)
      //       .map((v)=>v.key),
      //     {emitEvent: false, onlySelf: true}
      //   )
      // }
      //
      // if (this.processResultFilter.tasks.length === 0) {
      //   this.tasks?.patchValue(
      //     process.tasks
      //       .filter((v)=>v?.selected || false)
      //       .map((v)=>v.id)
      //   )
      // }

      // Mark form filter as changed to know if has filters applied
      this.dynamicFilterFormActive = Object.keys(this.dynamicFilterForm.value).reduce((acc, curr) => {
        // @ts-ignore
        if (Array.isArray(this.dynamicFilterForm.value[curr]) && this.dynamicFilterForm.value[curr].length > 0) return true;
        // @ts-ignore
        return acc || (this.dynamicFilterForm.value[curr] && this.dynamicFilterForm.value[curr].length);
      }, false)

    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  paginate(action: "next"|"last"|"first"|"previous"){
    if (this.selectedProcess) {
      switch (action){

        case "first":
          if (this.resultPaginator.previous){
            this.store.dispatch(setLoadingResult({status: true}));

            const {bookmarked:_, process_status:__, tasks:___,...filter}=this.processResultFilter

            this.store.dispatch(loadProcessResultList({
              processId: this.selectedProcess?.id,
              page: "1",
              limit: this.itemPerPage?.value,
              bookmarked: this.processResultFilter.bookmarked,
              process_status: this.processResultFilter.process_status,
              tasks: this.processResultFilter.tasks,
              filter: filter,
              globalSearch: this.globalSearch
            }))
          }
          break;

        case "previous":
          if (this.resultPaginator.previous) {
            this.store.dispatch(setLoadingResult({status: true}));

            const {bookmarked:_, process_status:__, tasks:___,...filter} = this.processResultFilter

            this.store.dispatch(loadProcessResultList({
              processId: this.selectedProcess.id,
              page: this.resultPaginator.previous,
              limit: this.itemPerPage?.value,
              bookmarked: this.processResultFilter.bookmarked,
              process_status: this.processResultFilter.process_status,
              tasks: this.processResultFilter.tasks,
              filter: filter,
              globalSearch: this.globalSearch
            }))
          }
          break;

        case "next":
          if (this.resultPaginator.next) {
            this.store.dispatch(setLoadingResult({status: true}));

            const {bookmarked:_, process_status:__, tasks:___,...filter} = this.processResultFilter;

            this.store.dispatch(loadProcessResultList({
              processId: this.selectedProcess.id,
              page: this.resultPaginator.next,
              limit: this.itemPerPage?.value,
              bookmarked: this.processResultFilter.bookmarked,
              process_status: this.processResultFilter.process_status,
              tasks: this.processResultFilter.tasks,
              filter: filter,
              globalSearch: this.globalSearch
            }))
          }
          break;

        case "last":
          if (this.resultPaginator.next){
            this.store.dispatch(setLoadingResult({status: true}));
            const lastPage = Math.floor(+this.resultPaginator.count/+this.itemPerPage?.value || +itemsPerPageList[0].value) + (this.resultPaginator.count % this.itemPerPage?.value > 0 ? 1 : 0)

            const {bookmarked:_, process_status:__, tasks:___,...filter} = this.processResultFilter;

            this.store.dispatch(loadProcessResultList({
              processId: this.selectedProcess.id,
              page: lastPage,
              limit: this.itemPerPage?.value,
              bookmarked: this.processResultFilter.bookmarked,
              process_status: this.processResultFilter.process_status,
              tasks: this.processResultFilter.tasks,
              filter: filter,
              globalSearch: this.globalSearch
            }))
          }
          break;
      }
    }
  }

  //Filter apply
  applyFilter(values: any) {
    console.log("Filter => ", values)

    this.store.dispatch(setProcessResultFilter({
      filter: { isInitial: false, ...values}
    }))

    if (this.selectedProcess) {
      this.store.dispatch(setDetailProcessResult({result: null}));
    }
  }




  toggleFilterMenu(){
    if (!this.showFilterBar){
      this.store.dispatch(setShowRightBar({status: false}));
    }
    this.store.dispatch(setShowFilterBar({status: !this.showFilterBar}));
  }

  toggleLeftBar() {
    this.store.dispatch(setShowFilterBar({status: !this.showFilterBar}))
  }
  toggleRightBar() {
    if (!this.showRightBar){
      this.store.dispatch(setShowFilterBar({status: false}));
    }
    this.store.dispatch(setShowRightBar({status: !this.showRightBar}))
  }

  setDetailsTab(tab: DetailTabs) {
    this.store.dispatch(setResultDetailsTab({tab: tab}));
  }



  addObjectPermission(value: TaskPermissionsObjectI) {
    if (this.detailsProcessResult){
      let acl = {...this.detailsProcessResult.acl};

      acl.objects = (this.detailsProcessResult?.acl?.objects || []).filter((val) => val.username !== value.object.username);
      acl.list = (this.detailsProcessResult?.acl?.list || []).filter((val) => val !== value.object.username);
      acl.read = (this.detailsProcessResult?.acl?.read || []).filter((val) => val !== value.object.username);
      acl.write = (this.detailsProcessResult?.acl?.write || []).filter((val) => val !== value.object.username);
      acl.delete = (this.detailsProcessResult?.acl?.delete || []).filter((val) => val !== value.object.username);

      acl.objects.push(value.object);
      if (value.permissions.list){ acl.list.push(value.object.username)}
      if (value.permissions.read){ acl.read.push(value.object.username)}
      if (value.permissions.write){ acl.write.push(value.object.username)}
      if (value.permissions.delete){ acl.delete.push(value.object.username)}

      this.store.dispatch(updateProcessResultPermissionsSuccess({
        result: {
          ...this.detailsProcessResult,
          acl: acl
        }
      }))

      this.store.dispatch(updateProcessResultPermissions({
        processId: this.detailsProcessResult.processId,
        resultId: this.detailsProcessResult.id,
        data: value
      }))
    }
  }

  removeObjectPermission(value: TaskPermissionsObjectI) {
    if (this.detailsProcessResult){
      let acl = {...this.detailsProcessResult.acl};

      acl.objects = (this.detailsProcessResult?.acl?.objects || []).filter((val) => val.username !== value.object.username);
      acl.list = (this.detailsProcessResult?.acl?.list || []).filter((val) => val !== value.object.username);
      acl.read = (this.detailsProcessResult?.acl?.read || []).filter((val) => val !== value.object.username);
      acl.write = (this.detailsProcessResult?.acl?.write || []).filter((val) => val !== value.object.username);
      acl.delete = (this.detailsProcessResult?.acl?.delete || []).filter((val) => val !== value.object.username);

      this.store.dispatch(updateProcessResultPermissionsSuccess({
        result: {
          ...this.detailsProcessResult,
          acl: acl
        }
      }))

      this.store.dispatch(removeProcessResultPermissions({
        resultId: this.detailsProcessResult.id,
        processId: this.detailsProcessResult.processId,
        data: value
      }))
    }

  }

  changeViewComponent(type: ViewComponentType) {
    this.store.dispatch(setShowRightBar({status: false}));
    this.store.dispatch(setShowFilterBar({status: false}));
    this.store.dispatch(setDetailProcessResult({result: null}));
    this.store.dispatch(setViewComponentProcessResult({value: type}))
  }



  get itemPerPage() {
    return this.filterForm ? this.filterForm.get("itemPerPage") : null
  }

  get bookmarked() {
    return this.dynamicFilterForm ? this.dynamicFilterForm.get("bookmarked") : null
  }

  get process_status() {
    return this.dynamicFilterForm ? this.dynamicFilterForm.get("process_status") : null
  }

  get tasks() {
    return this.dynamicFilterForm ?  this.dynamicFilterForm.get("tasks") : null
  }

  change_all_process_status(value: boolean){
    value ? this.processResultStatusFilterSelectAll.next(value) : this.processResultStatusFilterClearAll.next(value);
  }

  change_all_process_tasks(value: boolean){
    value ? this.processResultTaskFilterSelectAll.next(value) : this.processResultTaskFilterClearAll.next(value);
  }

}
