import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";
import {map, mergeMap, Observable, Subject, Subscriber} from "rxjs";
import {PermissionsObjectI} from "../../../infrastructure/interfaces/permissions.interface";
import {TaskPermissionsObjectI} from "../../../infrastructure/interfaces/task.interface";

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit, OnDestroy{

  @Input() selectedObjects: TaskPermissionsObjectI[] = []
  @Input() objects: Observable<PermissionsObjectI[]> = new Observable<PermissionsObjectI[]>()
  @Input() displayField: string = "name"
  @Input() keyField: string = "username"
  @Input() typeField: string = "type"
  @Input() pictureField: string = "picture"
  @Input() usersField: string = "users"
  @Input() loading: boolean = false
  @Input() language: any = {}

  @Output() objectPermissionAdded: EventEmitter<TaskPermissionsObjectI> = new EventEmitter<TaskPermissionsObjectI>();
  @Output() objectPermissionRemoved: EventEmitter<TaskPermissionsObjectI> = new EventEmitter<TaskPermissionsObjectI>();

  destroy$ = new Subject<void>();

  selected!: any;

  dataSource: Observable<PermissionsObjectI[]>;

  constructor() {
    this.dataSource = new Observable((observer: Subscriber<string>) => {
      // Runs on every search
      observer.next(this.selected);
    })
      .pipe(
        mergeMap((search: string) => {
          return this.objects.pipe(
            map((data: any[]) => {
              const query = new RegExp(search, 'i');
              return data
                .filter((value) => {
                  return this.selectedObjects.findIndex((val: any)=>val.object?.[this.keyField] === value?.[this.keyField]) === -1
                })
                .filter((value) => {
                return query.test(value[this.displayField])
              })
            })
          )
        })
      );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectUserGroup(match: TypeaheadMatch, $event: MouseEvent){
    $event.preventDefault();
    this.objectPermissionAdded.emit({
      object: match.item as PermissionsObjectI,
      permissions: {
        list: false,
        read: false,
        write: false,
        delete: false
      }
    });
  }

  changeObjectPermissions(item: TaskPermissionsObjectI){
    this.objectPermissionAdded.emit(item);
  }

  removeObjectPermissions(item: TaskPermissionsObjectI){
    this.objectPermissionRemoved.emit(item);
  }

  get selectedObjectsUser() {
    return (this.selectedObjects as any)
      .filter((val: any) => val.object.type === 'user')
      .sort((a: any,b: any) => a?.object?.[this.displayField] > b?.object?.[this.displayField] ? 1 : a?.object?.[this.displayField] === b?.object?.[this.displayField] ? 0 : -1 )
  }

  get selectedObjectsGroup() {
    return this.selectedObjects
      .filter((val: any) => val.object.type === 'group')
      .sort((a: any,b: any) => a?.object?.[this.displayField] > b?.object?.[this.displayField] ? 1 : a?.object?.[this.displayField] === b?.object?.[this.displayField] ? 0 : -1 )
  }

}
