<div class="p-3">
  <div class="d-flex flex-grow-1 justify-content-end align-items-center">
    <span
      class="material-symbols-outlined text-neutral-color-h text-title fw-bold pointer"
      (click)="bsModalRef.hide()"
    >
      close
    </span>
  </div>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center pb-3">
    <span class="material-symbols-outlined text-warning " style="font-size: 80px">
      warning
    </span>
  </div>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center pb-3">
    <span class="text-neutral-color-h user-select-none text-center" style="font-size: 24px">
      {{language?.areYouSure || 'Are you sure?'}}
    </span>
  </div>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center pb-4">
    <span class="text-neutral-color-h user-select-none text-sub-title text-center">
      {{message}}
    </span>
  </div>
  <div class="d-flex flex-grow-1 gap-2 justify-content-center align-items-center pb-2">
    <div
      class="d-flex justify-content-center align-items-center action-btn border border-neutral-color-c rounded-2 pointer"
      (click)="bsModalRef.hide()"
    >
      <span class="text-normal user-select-none">{{language?.cancel ||  'Cancel' | titlecase}}</span>
    </div>
    <div
      class="d-flex justify-content-center align-items-center bg-warning action-btn border border-warning rounded-2 pointer"
      (click)="accept()"
    >
      <span class="text-normal text-white fw-bold user-select-none">{{language?.accept ||  'Accept' | titlecase}}</span>
    </div>
  </div>
</div>
