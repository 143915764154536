<div  *ngIf="resultList.length > 0 && !loadingProcessResult" class="overflow-auto h-100">
  <!-- Table -->
  <table class="table table-responsive">
    <!-- Header -->
    <thead>
    <tr class="process-row-header">
      <div class="upper"></div>
      <div class="lower"></div>
      <div class="overlay"></div>
      <th class="align-middle px-3" scope="col">
              <span
                class=""
              >
                <input
                  class="form-check-input pointer p-1"
                  type="checkbox" value=""
                  (change)="toggleSelectAll($event)"
                >
              </span>
      </th>
      <th
        *ngFor="let header of process?.tableDashboard?.headers; let headerLast=last" scope="col" class="align-middle"
        [style]="{width: header.component === 'TaskStatus' ? headerLast ? '246px': '200px' : 'auto' }"
      >
        <span class="text-sub-title fw-bold text-complementary-color-a">{{header.label}}</span>
      </th>
    </tr>
    </thead>

    <!-- Body -->
    <tbody>
    <!-- Process Result Row -->
    <ng-container *ngFor="let result of resultList; let index=index; let first=first; let last=last">
      <!-- Row Data -->
      <tr
        class="align-middle process-row"
        [class.selected]="detailsProcessResult?.id === result.id"
      >
        <!-- border up -->
        <div class="upper"></div>
        <!-- border down -->
        <div class="lower"></div>
        <!-- Table row overlay -->
        <div
          class="overlay"
        >
          <ng-container
            *ngTemplateOutlet="actions, context:{result: result, index: index, first: first, last: last}"
          ></ng-container>
        </div>
        <!-- Item Selector Column-->
        <td class="align-middle px-3">
                <span class="">
                  <input
                    class="form-check-input pointer"
                    type="checkbox" value=""
                    [checked]="selectedIds.has(result.processId)"
                    (change)="toggleSelection(result.processId, $event)"
                  >
                </span>
        </td>

        <!-- Dynamic Columns -->
        <td
          (click)="setDetailsProcessResult($event, result, index)"
          class="align-middle col-option"
          *ngFor="let head of process?.tableDashboard?.headers;
                let headIndex=index;
                let headFirst=first;
                let headLast=last"
        >
          <ng-container
            *ngTemplateOutlet="columnValue, context:{header: head, result: result, index: index, headIndex: headIndex, first: headFirst, last: headLast}"
          ></ng-container>
        </td>

        <td class="icons-container">
          <!-- Edit Process Result Action -->
          <span
            *ngIf="result.user_permissions.write && result.percentComplete < 100"
            class="material-symbols-outlined text-title pointer p-1 user-select-none"
            tooltip="{{language?.edit || 'edit' | titlecase}}"
            containerClass="text-mini fw-bold"
            (click)="editResult($event, index)"
          >
                    edit_square
                </span>

          <!-- View Process Result Action -->
          <span
            *ngIf="result.user_permissions.write && result.percentComplete >= 100"
            class="material-symbols-outlined text-title pointer p-1 user-select-none"
            tooltip="{{language?.show || 'show' | titlecase}}"
            containerClass="text-mini fw-bold"
            (click)="showResult($event, index)"
          >
                  visibility
                </span>

          <!-- Remove Process Result Action -->
          <span
            *ngIf="result.user_permissions.delete"
            class="material-symbols-outlined text-title p-1 pointer user-select-none"
            tooltip="{{language?.delete || 'delete' | titlecase}}"
            containerClass="text-mini fw-bold"
            (click)="remove($event, result)"
          >
                  delete
                </span>

          <!-- Process Result Security Action -->
          <span
            *ngIf="result.user_permissions.write"
            class="material-symbols-outlined text-title p-1 pointer user-select-none"
            tooltip="{{language?.security || 'security' | titlecase}}"
            containerClass="text-mini fw-bold"
            (click)="showProcessResultSecurity($event, result)"
          >
                  admin_panel_settings
                </span>

          <!-- Show Info Action -->
          <span
            class="material-symbols-outlined text-title p-1 pointer user-select-none"
            tooltip="{{language?.details || 'details' | titlecase}}"
            containerClass="text-mini fw-bold"
            (click)="showProcessResultDetails($event, result)"
          >
                  info
                </span>
        </td>
      </tr>

      <!-- Row Details -->
      <tr *ngIf="resultListDropdown[index] && result?.tasks">
        <td class="p-0" [colSpan]=tableCol>
          <div class="d-flex px-0">
            <!-- First col fixed-->
            <div class="d-flex flex-column border-end px-3 py-2 gap-2" style="width: 200px" >
              <div  class="d-flex justify-content-center align-items-start ">
                <label class="text-sub-title text-complementary-color-a">
                  {{language?.details || 'details' | titlecase}}
                </label>
              </div>
              <div class=" flex-grow-1 w-100 d-flex justify-content-center align-content-start">
                <div class="" style="width: 100px; height: 100px">
                  <circle-progress
                    [percent]="result?.percentComplete || 0"
                    [radius]="100"
                    [outerStrokeWidth]="12"
                    [innerStrokeWidth]="12"
                    [space]="-12"
                    [outerStrokeColor]="'#6AA84F'"
                    [innerStrokeColor]="'#E7E7E7'"
                    [titleFontSize]="'33'"
                    [unitsFontSize]="'33'"
                    [showSubtitle]="false"
                    [animation]="true"
                    [animationDuration]="300"
                    [startFromZero]="false"
                    [responsive]="true"
                  ></circle-progress>
                </div>
              </div>

            </div>

            <div
              *ngFor="let task of result?.tasks; let taskIndex=index; let taskLast=last; let taskFirst=first "
              class="flex-grow-1 px-3 py-2"
              [class.border-end]="!taskLast"
            >
              <div class="pt-1 ps-0 pe-0">
                <div class="d-flex justify-content-start align-items-start py-1">
                  <div
                    class="main-task d-flex justify-content-center align-items-center"
                    [ngStyle]="{
                          'background-color': status_map?.[task.status.key] || status_map['empty']
                          }"
                  >
                    <span class="text-mini">{{taskIndex + 1}}</span>
                  </div>
                  <label class="text-normal ps-1">{{task.label}}</label>
                </div>
                <ng-container *ngTemplateOutlet="recursiveTree; context:{result: result, itemList: task.children, root: true }"></ng-container>
              </div>


            </div>
          </div>

        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

<div  *ngIf="loadingProcessResult" class="overflow-auto h-100">
  <table class="table table-responsive table-skeleton d-none d-sm-table">
    <tr [class.tb-header]="first" style="height: 15px"
        *ngFor="let r of [].constructor(rows); let first=first"
    >
      <td *ngFor="let c of [].constructor(4)" style="height: 15px; padding: 5px 5px; border-bottom: 1px solid var(--status-color-occult)">
        <div class="skeleton-box"></div>
      </td>
    </tr>

  </table>
  <table class="table table-responsive table-skeleton d-sm-none">
    <tr [class.tb-header]="first" style="height: 15px"
        *ngFor="let r of [].constructor(rows); let first=first"
    >
      <td *ngFor="let c of [].constructor(1)" style="height: 15px; padding: 5px 5px; border-bottom: 1px solid var(--status-color-occult)">
        <div class="skeleton-box"></div>
      </td>
    </tr>

  </table>
</div>

<!-- No Data to show -->
<div
  *ngIf="resultList.length === 0 && !loadingProcessResult"
  class="w-100 h-100 px-0 d-flex flex-column justify-content-center"
>
  <div class="w-100 d-flex justify-content-center">
    <img class="" src="assets/img/arrow.svg" style="width: 80px; transform: rotate(-10deg) translate(60px, 0px)" />
  </div>
  <div class="w-100 d-flex justify-content-center">
    <img
      class="no-process-icon"
      src="assets/img/icon-process-white.svg"
    />
  </div>
  <div class="w-100 d-flex justify-content-center">
          <span class="text-sub-title text-neutral-color-e text-center">
            {{language?.noProcessToDisplay || 'This view currently has no processes to display'}}.
            <br>
            {{language?.toCreateNew || 'To create a new one, you can do so from the'}}
            <span
              class="fw-bold"
            >{{language?.new || 'nuevo' | titlecase}}</span>
            {{language?.buttonLocated || 'button located at the top right corner' }}
          </span>
  </div>
</div>

<!-- Template for actions -->
<ng-template #actions let-result=result let-index=index let-first=first let-last=last>
  <div class="d-none justify-content-end align-items-center w-100 h-100 px-3">
    <!-- Edit Process Result Action -->
    <span
      *ngIf="result.user_permissions.write && result.percentComplete < 100"
      class="material-symbols-outlined text-title pointer p-1 user-select-none"
      tooltip="{{language?.edit || 'edit' | titlecase}}"
      containerClass="text-mini fw-bold"
      (click)="editResult($event, index)"
    >
      edit_square
    </span>


    <!-- View Process Result Action -->
    <span
      *ngIf="result.user_permissions.write && result.percentComplete >= 100"
      class="material-symbols-outlined text-title pointer p-1 user-select-none"
      tooltip="{{language?.show || 'show' | titlecase}}"
      containerClass="text-mini fw-bold"
      (click)="showResult($event, index)"
    >
      visibility
    </span>


    <!-- Remove Process Result Action -->
    <span
      *ngIf="result.user_permissions.delete"
      class="material-symbols-outlined text-title p-1 pointer user-select-none"
      tooltip="{{language?.delete || 'Delete' | titlecase}}"
      containerClass="text-mini fw-bold"
      (click)="remove($event, result)"
    >
      delete
    </span>

    <!-- Process Result Security Action -->
    <span
      *ngIf="result.user_permissions.write"
      class="material-symbols-outlined text-title p-1 pointer user-select-none"
      tooltip="{{language?.security || 'Security' | titlecase}}"
      containerClass="text-mini fw-bold"
      (click)="showProcessResultSecurity($event, result)"
    >
      admin_panel_settings
    </span>

    <!-- Show Info Action -->
    <span
      class="material-symbols-outlined text-title p-1 pointer user-select-none"
      tooltip="{{language?.details || 'Details' | titlecase}}"
      containerClass="text-mini fw-bold"
      (click)="showProcessResultDetails($event, result)"
    >
      info
    </span>
  </div>
</ng-template>


<!-- Template for tasks recursive tree -->
<ng-template #recursiveTree let-result=result let-itemList="itemList" let-root="root">
  <div
    *ngFor="let task of itemList"
    class="py-1 ps-2 pe-0"
    [ngClass]="{
      'hover:bg-status-color-active': task.type !== taskType.GROUP,
      'pointer': task.type !== taskType.GROUP
      }"
    (click)="goToTask(result, task)"
  >
    <div class="d-flex justify-content-start align-items-center">
      <div
        class="secondary-task d-flex justify-content-center align-items-center "
        [ngStyle]="{
          'background-color': status_map?.[task.status.key] || status_map['empty']
          }"
        [ngClass]="{
          'pointer': task.type !== taskType.GROUP
          }"
      >
      </div>
      <label
        class="text-normal ps-2"
        [ngClass]="{
          'pointer': task.type !== taskType.GROUP
          }"
      >{{task.label}}</label>
    </div>
    <ng-container *ngTemplateOutlet="recursiveTree; context:{result: result, itemList: task.children }"></ng-container>
  </div>
</ng-template>


<!-- Template for column dynamic values -->
<ng-template #columnValue let-header=header let-result=result let-index=index let-headIndex=headIndex let-first=first let-last=last>
  <ng-container [ngSwitch]="header.component">
    <!-- Values date type -->
    <div
      *ngSwitchCase="'FormattedDate'"
      class="d-flex justify-content-start align-items-center"
    >
      <span
        *ngIf="result?.bookmarked === bookmarketType.active && first"
        class="material-symbols-sharp text-primary-color text-sub-title text- pe-2"
      >
        bookmark
      </span>
      <span
        *ngIf="result.data[header.key]"
        class="text-normal"
      >{{ result.data[header.key] | date: "YYYY-MM-dd" }} {{ result.data[header.key] | date: "mediumTime" }}</span>
      <span
        class="text-normal truncate truncate-1"
        *ngIf="!result.data[header.key]"
      >{{language?.toBeDefined || 'Not Defined'}}</span>
      <span
        *ngIf="last"
        class="material-symbols-sharp text-complementary-color-a text-title text- pe-2"
      >
        {{resultListDropdown[index] ? 'arrow_drop_down' : 'arrow_drop_up'}}
      </span>
    </div>

    <!-- Values TaskStatus type -->
    <div
      *ngSwitchCase="'TaskStatus'"
      class="d-flex justify-content-start align-items-center"
    >
      <span
        *ngIf="result?.bookmarked === bookmarketType.active && first"
        class="material-symbols-sharp text-sub-title text-primary-color pe-2"
      >
        bookmark
      </span>
      <span
        class="text-normal"
      >
        <app-tasks-status [processResult]="result" [processModel]="this.process"></app-tasks-status>
      </span>
      <span
        *ngIf="last"
        class="material-symbols-sharp text-complementary-color-a text-title pe-2"
      >
        {{resultListDropdown[index] ? 'arrow_drop_down' : 'arrow_drop_up'}}
      </span>
    </div>

    <!-- Values text type and default -->
    <div
      *ngSwitchDefault
      class="d-flex justify-content-start align-items-center h-100"
    >
      <span
        *ngIf="result?.bookmarked === bookmarketType.active && first"
        class="material-symbols-sharp text-sub-title text-primary-color pe-2"
      >
        bookmark
      </span>
      <span
        *ngIf="result.data[header.key]"
        class="text-normal"
      >
        {{ result.data[header.key] }}
      </span>
      <span
        *ngIf="!result.data[header.key]"
        class="text-normal"
      >
        {{language?.toBeDefined || 'Not Defined'}}
      </span>
      <span
        *ngIf="last"
        class="material-symbols-sharp text-complementary-color-a text-title text- pe-2"
      >
        {{resultListDropdown[index] ? 'arrow_drop_down' : 'arrow_drop_up'}}
      </span>
    </div>
  </ng-container>
</ng-template>
