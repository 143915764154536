<!-- Full -->
<div
  class="d-flex align-items-center"
  *ngIf="selectedState && selectorType === 'full'"
  style="max-width: 160px"
  [ngStyle]="{
  'width': width
  }"
>
  <div
    class="d-inline-flex w-auto justify-content-center align-items-center rounded-pill pointer user-select-none"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    style="padding: 0px 6px;"
    [ngStyle]="{
    'background-color': selectedState.color
    }"
    *ngIf="editable && stateList.length > 0"
    [adaptivePosition]="false"
    tooltip="{{width !== 'auto' ? selectedState.label : ''}}"
    containerClass="text-mini fw-bold mb-1"
  >
    <span class="text-mini lh-0 ps-1 truncate truncate-1" [ngStyle]="{'color': selectedState.fontColor}">
      {{language?.[selectedState.key] || selectedState.label}}
    </span>
    <span class="material-symbols-outlined text-title lh-0"  [ngStyle]="{'color': selectedState.fontColor}"  style="padding-top: 1px">
      arrow_drop_down
    </span>
  </div>

  <div
    class="d-flex justify-content-center align-items-center rounded-pill user-select-none"
    aria-expanded="false"
    style="padding: 2px 10px"
    [ngStyle]="{
    'background-color': selectedState.color
    }"
    *ngIf="!editable || stateList.length === 0"
  >
    <span class="text-mini lh-0"  [ngStyle]="{'color': selectedState.fontColor}">
      {{language?.[selectedState.key] || selectedState.label}}
    </span>
  </div>

  <ul
    *ngIf="editable && stateList.length > 0"
    class="dropdown-menu p-2 flex-column gap-2" style="background-color: var(--neutral-color-f); min-width: auto !important;"
  >
    <ng-container *ngFor="let state of process_state_list; let stateFirst=first; let stateLast=last">
      <li
      >
        <div
          class="pointer menu-state mb-2 d-flex align-items-center justify-content-center gap-1 rounded-pill"
          style="padding: 2px 10px"
          [class.mb-2]="!stateLast"
          [style]="{
          'background-color': state.color
          }"
          (click)="selectState(state)"
        >
          <span
            class="material-symbols-outlined text-sub-title"
            [ngStyle]="{'color': state.fontColor}"
          >
            {{state.icon}}
          </span>
          <span class="text-mini" [ngStyle]="{'color': state.fontColor}">
            {{ language?.[state.key] || state.label }}
          </span>
        </div>

      </li>
    </ng-container>
  </ul>
</div>

<!-- Light -->
<div
  class="d-flex align-items-center"
  *ngIf="selectedState && selectorType === 'light' "
  style="max-width: 160px"
  [ngStyle]="{
  'width': width
  }"
>
  <div
    class="hover:bg-neutral-color-c border px-2 py-1 d-inline-flex w-auto justify-content-center align-items-center rounded-2 pointer user-select-none"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    *ngIf="editable && stateList.length > 0"
    [adaptivePosition]="false"
    tooltip="{{width !== 'auto' ? selectedState.label : ''}}"
    containerClass="text-mini fw-bold mb-1"
  >
    <div
      class="rounded-circle border-2 d-flex justify-content-center align-items-center"
      [ngStyle]="{
        'width': '14px',
        'height': '14px',
        'border-color': selectedState.color
      }"
    >
      <div
        class="rounded-circle" s
        [ngStyle]="{
          'width': '6px',
          'height': '6px',
          'background-color': selectedState.color
        }"
      ></div>
    </div>
    <span class="text-mini lh-0 ps-1 truncate truncate-1">
      {{selectedState.label}}
    </span>
  </div>

  <div
    class="d-flex justify-content-center align-items-center rounded-pill user-select-none"
    aria-expanded="false"
    style="padding: 2px 10px"
    [ngStyle]="{
    'background-color': selectedState.color
    }"
    *ngIf="!editable || stateList.length === 0"
  >
    <span class="text-mini lh-0"  [ngStyle]="{'color': selectedState.fontColor}">
      {{selectedState.label}}
    </span>
  </div>

  <ul
    *ngIf="editable && stateList.length > 0"
    class="dropdown-menu p-2 flex-column gap-2" style="background-color: var(--neutral-color-f); min-width: auto !important;"
  >
    <ng-container *ngFor="let state of process_state_list; let stateFirst=first; let stateLast=last">
      <li
      >
        <div
          class="pointer menu-state mb-2 d-flex align-items-center justify-content-center gap-1 rounded-pill"
          style="padding: 2px 10px"
          [class.mb-2]="!stateLast"
          [style]="{
          'background-color': state.color
          }"
          (click)="selectState(state)"
        >
          <span
            class="material-symbols-outlined text-sub-title"
            [ngStyle]="{'color': state.fontColor}"
          >
            {{state.icon}}
          </span>
          <span class="text-mini" [ngStyle]="{'color': state.fontColor}">
            {{ state.label }}
          </span>
        </div>

      </li>
    </ng-container>
  </ul>
</div>
