import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BookmarketType, ProcessResultI} from "../../../infrastructure/interfaces/process-result.interface";
import {TaskI} from "../../../infrastructure/interfaces/task.interface";
import {Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import {
  selectDetailsProcessResult,
  selectLoadingBookmarked, selectLoadingDetailProcessResult
} from "../../../infrastructure/store/process-result/process-result.selector";
import {
  bookmarkProcessResult, changeStatusProcessResult, changeStatusProcessResultSuccess,
  setLoadingBookmarked
} from "../../../infrastructure/store/process-result/process-result.actions";
import {ProcessI, TaskStateI} from "../../../infrastructure/interfaces/process.interface";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {ConfirmationModalComponent} from "../modals/confirmation-modal/confirmation-modal.component";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {TranslateService} from "@mangosoft/mango-layout";

@Component({
  selector: 'app-process-details',
  templateUrl: './process-details.component.html',
  styleUrls: ['./process-details.component.scss']
})
export class ProcessDetailsComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  @Input() actions: boolean = false;
  processModel: ProcessI | null = null;
  processResult: ProcessResultI | TaskI | null = null;

  bookMarketType = BookmarketType;

  loadingBookmarked: boolean = false;
  loadingDetailProcessResult: boolean = false;

  bsModalRef?: BsModalRef;

  language!: any;

  constructor(
    private store: Store,
    private modalService: BsModalService,
    private translateService: TranslateService
  ) {
    this.translateService
      .init("process-manager")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.language = data
      });
  }

  ngOnInit() {
    // Listen for selected process changes
    this.store.select(selectSelectedProcess).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.processModel = value
    })

    // Listen for loading bookmarked
    this.store.select(selectLoadingBookmarked).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingBookmarked = value;
    })

    // Listen for process detail result changes
    this.store.select(selectDetailsProcessResult).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.processResult = value
    })

    //Listen for loading detail process result changes
    this.store.select(selectLoadingDetailProcessResult).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingDetailProcessResult = value;
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setBookmarkProcessResult(bookmarked: BookmarketType) {
    if (this.processModel && this.processResult && this.processResult.user_permissions.write) {
      this.store.dispatch(setLoadingBookmarked({status: true}));
      this.store.dispatch(bookmarkProcessResult({
        processId: this.processModel.id,
        resultId: this.processResult.id,
        data:{
          ...this.processResult,
          bookmarked: bookmarked
        } as ProcessResultI,
      }))
    }

  }

  processStatusChange(status: TaskStateI){
    if (this.processResult && this.processModel) {
      if (status.message){
        const initialState: ModalOptions = {
          initialState: {
            message: status.message,
            item: {...this.processResult, process_status: status}
          },
          backdrop: "static",
          keyboard: false,
          class: "modal-sm"
        };
        this.bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);
        this.bsModalRef.content.action.pipe(
          takeUntil(this.destroy$)
        ).subscribe((value: ProcessResultI) => {
          if (value && this.processModel && this.processResult) {
            this.store.dispatch(changeStatusProcessResultSuccess({
              previousStatus: this.processResult.process_status,
              index: null,
              result: value,
            }))
            this.store.dispatch(changeStatusProcessResult({
              previousStatus: this.processResult.process_status,
              processId: this.processModel.id,
              resultId: value.id,
              data: value,
            }))
          }
        })
      } else {
        this.store.dispatch(changeStatusProcessResultSuccess({
          previousStatus: this.processResult.process_status,
          index: null,
          result: {...this.processResult, process_status: status} as ProcessResultI,
        }))
        this.store.dispatch(changeStatusProcessResult({
          previousStatus: this.processResult.process_status,
          processId: this.processModel.id,
          resultId: this.processResult.id,
          data: {...this.processResult, process_status: status} as ProcessResultI,
        }))
      }
    }
  }

}
