import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BookmarketType} from "../../../../infrastructure/interfaces/process-result.interface";
import {TranslateService} from "@mangosoft/mango-layout";
import {takeUntil} from "rxjs";

@Component({
  selector: 'bookmark-field',
  templateUrl: './bookmark-field.component.html',
  styleUrls: ['./bookmark-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookmarkFieldComponent),
      multi: true,
    },
  ],
})
export class BookmarkFieldComponent implements ControlValueAccessor{

  @Input() language: any = {};

  bookmarkedType = BookmarketType;

  value: BookmarketType | boolean = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(obj: BookmarketType | boolean): void {
    this.value = obj;
  }

  toggleValue(){
    this.value = this.value === this.bookmarkedType.active ? this.bookmarkedType.inactive : this.bookmarkedType.active;
    this.onChange(this.value === this.bookmarkedType.active ? this.value: false);
    this.onTouch(this.value === this.bookmarkedType.active ? this.value: false);
  }

}
