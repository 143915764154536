<div
  dndDropzone
  (dndDrop)="onDrop($event)"
>
  <div
    dndPlaceholderRef
    class="d-flex border bg-white rounded-2 kanban-card p-2 mb-1"
    [ngStyle]="{
      'width': total > 4 ? '260px' : '100%',
      'height': height + 'px',
    }"
  >

  </div>

  <div
    *ngIf="processResult.processId"
    class="d-flex border bg-white rounded-2 kanban-card p-2"
    [ngClass]="{
    'mb-1': !last,
   }"
    [ngStyle]="{
    'cursor': 'move',
    'width': total > 4 ? '260px' : '100%',
    'height': height + 'px',
  }"
  >
    <div class="h-100 flex-grow-1 d-flex flex-column px-1">
      <div
        class="flex-grow-1 d-flex justify-content-start align-items-start"
      >
        <app-process-state
          [selectorType]="'light'"
          [stateList]="selectedProcess?.process_states?.states_list || []"
          [selectedState]="processResult?.process_status || null"
          [editable]="processResult?.user_permissions?.write || false"
          (onSelectState)="processStatusChange($event)"
        ></app-process-state>
      </div>
      <div
        class="flex-grow-1 d-flex justify-content-start align-items-start"
        *ngFor="let item of processResult.cardData"
      >
        <div class="h-100 d-flex justify-content-start align-items-center">
        <span class="material-symbols-outlined text-title">
          {{item.icon}}
        </span>
        </div>
        <div class="h-100 d-flex justify-content-start align-items-center ps-1">
          <span *ngIf="item.value" class="text-normal truncate truncate-1">{{item.value}}</span>
          <span *ngIf="!item.value" class="text-normal truncate truncate-1">{{language?.toBeDefined || 'To be defined'}}</span>
        </div>
      </div>
    </div>
    <div class="h-100 flex-shrink-0 d-flex flex-column pe-1" style="width: 80px">
      <div class="flex-grow-1 d-flex justify-content-end">
        <app-circular-progress
          [color]="'#6AA84F'"
          [background]="'#E7E7E7'"
          [border]="'4px'"
          [value]="processResult?.percentComplete || 0"
          [width]="'70px'"
          [animate]="false"
          [fontSize]="'12px'"
        ></app-circular-progress>
      </div>
      <div class="d-flex justify-content-end pb-1">
        <span
          class="text-normal text-primary-color fw-semibold pointer hover:text-complementary-color-a"
          (click)="goto()"
        >
          {{language?.openProcess || 'Open process'}}
        </span>
      </div>

      <!--      <circle-progress-->
      <!--        [percent]="processResult?.percentComplete || 0"-->
      <!--        [radius]="100"-->
      <!--        [outerStrokeWidth]="12"-->
      <!--        [innerStrokeWidth]="12"-->
      <!--        [space]="-12"-->
      <!--        [outerStrokeColor]="'#6AA84F'"-->
      <!--        [innerStrokeColor]="'#E7E7E7'"-->
      <!--        [titleFontSize]="'33'"-->
      <!--        [unitsFontSize]="'33'"-->
      <!--        [showSubtitle]="false"-->
      <!--        [animation]="true"-->
      <!--        [animationDuration]="300"-->
      <!--        [startFromZero]="false"-->
      <!--        [responsive]="true"-->
      <!--      ></circle-progress>-->
    </div>
  </div>
</div>


<div
  *ngIf="!processResult.processId"
  class="d-flex p-2 rounded-2 kanban-card-skeleton"
  [ngClass]="{
  'mb-1': !last,
 }"
  [ngStyle]="{
  'width': total > 4 ? '260px' : '100%',
  'height': height + 'px'
}"
>
</div>

