import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, filter, Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {
  selectDetailsProcessResult, selectLoadingResults,
  selectProcessResultFilter, selectProcessResultGlobalSearch,
  selectProcessResultList, selectProcessResultPaginator
} from "../../../infrastructure/store/process-result/process-result.selector";
import {
  BookmarketType,
  ProcessResultFilterI,
  ProcessResultI, ProcessResultPaginatorI
} from "../../../infrastructure/interfaces/process-result.interface";
import {ProcessResultService} from "../../../infrastructure/services/process-result.service";
import {
  DetailTabs,
  TaskI,
  TaskPermissionsObjectI,
  TaskStatus,
  TaskType
} from "../../../infrastructure/interfaces/task.interface";
import {Router} from "@angular/router";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {RemoveModalComponent} from "../modals/remove-modal/remove-modal.component";
import {
  deleteProcessResult,
  loadDetailProcessResult,
  loadProcessResultList,
  setDetailProcessResult,
  setLoadingDetailProcessResult,
  setLoadingResult,
  setResultDetailsTab,
  setShowRightBar
} from "../../../infrastructure/store/process-result/process-result.actions";
import {initialProcessResultPaginator} from "../../../infrastructure/store/process-result/state";
import {LocalStorageService} from "../../../infrastructure/services/local-storage.service";
import {TranslateService} from "@mangosoft/mango-layout";

@Component({
  selector: 'app-process-result-table',
  templateUrl: './process-result-table.component.html',
  styleUrls: ['./process-result-table.component.scss']
})
export class ProcessResultTableComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  bookmarketType = BookmarketType;
  taskType = TaskType;
  detailTabs = DetailTabs;

  process: ProcessI | null = null;

  bsModalRef?: BsModalRef;

  loadingProcessResult: boolean = true;
  originalResultList: ProcessResultI[] = [];
  resultList: ProcessResultI[] =[];
  resultListDropdown: boolean[] = [];

  status_map: { [key:string]:string } = {};
  selectedIds: Set<string> = new Set();
  tableCol: number = 0;
  rows: number = initialProcessResultPaginator.limit;

  resultPaginator: ProcessResultPaginatorI = initialProcessResultPaginator;

  processResultFilter!: ProcessResultFilterI;

  processResultGlobalSearch: string = "";

  detailsProcessResult: ProcessResultI | null = null;
  detailsProcessResultPermissions: TaskPermissionsObjectI[] = [];

  language!: any

  constructor(
    private store:Store,
    private router: Router,
    private processResultService: ProcessResultService,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService
  ) {
    this.translateService
      .init("process-manager")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.language = data
      });
  }

  ngOnInit() {
    // Listen for selected process changes
    combineLatest([
      this.store.select(selectProcessResultFilter),
      this.store.select(selectProcessResultGlobalSearch),
      this.store.select(selectSelectedProcess),
    ])
    .pipe(
      takeUntil(this.destroy$),
      filter(([processResultFilter, processResultGlobalSearch, process]) => process !== null)
    ).subscribe(([processResultFilter, processResultGlobalSearch, process]) => {
      this.process = process;
      this.processResultFilter = processResultFilter;
      this.processResultGlobalSearch = processResultGlobalSearch;

      if (this.process) {
        this.tableCol = this.process.tableDashboard.headers.length + 2;

        this.status_map = this.process.task_states.states_list.reduce((acc: any, curr) => {
          acc[curr.key] = curr.color
          return acc
        },{'empty': '#DADADA'})

        // turn loading result on
        this.store.dispatch(setLoadingResult({status: true}));

        //Load results of process
        const {tasks:_, bookmarked:__, process_status:___, ...filter} = processResultFilter;

        this.store.dispatch(loadProcessResultList({
          processId: this.process.id,
          page: 1,
          limit: this.localStorageService.getItemPerPage() || initialProcessResultPaginator.limit,
          bookmarked: processResultFilter.bookmarked,
          process_status: processResultFilter.process_status,
          tasks: processResultFilter.tasks,
          filter: filter,
          globalSearch: processResultGlobalSearch
        }))
      }
    })

    // Listen for result paginator changes
    this.store.select(selectProcessResultPaginator).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.resultPaginator = value;
    })

    // Listen for result list changes
    this.store.select(selectProcessResultList).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.originalResultList = value;
      this.resultList = value.map((result) => {
        return {...result, tasks: this.processResultService.createTaskTree(result.tasks)}
      });
      this.resultListDropdown = this.resultList.map((val) => false);
    })

    // Listen for Details Task changes
    this.store.select(selectDetailsProcessResult).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.detailsProcessResult = value;
      this.detailsProcessResultPermissions = [];
      if (this.detailsProcessResult){
        this.detailsProcessResultPermissions = this.detailsProcessResult.acl.objects.map((value) => {
          return {
            object: {
              ...value
            },
            permissions: {
              list: (this.detailsProcessResult?.acl.list || []).filter((val) => value.username === val).length > 0,
              read: (this.detailsProcessResult?.acl.read || []).filter((val) => value.username === val).length > 0,
              write: (this.detailsProcessResult?.acl.write || []).filter((val) => value.username === val).length > 0,
              delete: (this.detailsProcessResult?.acl.delete || []).filter((val) => value.username === val).length > 0,
            }
          }
        })
      }
    })

    //listen for loading process result list
    this.store.select(selectLoadingResults).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingProcessResult = value
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSelectAll(event: any): void {
    if (event.target.checked) {
      this.resultList.forEach(val => this.selectedIds.add(val.processId));
    } else {
      this.selectedIds.clear();
    }
  }

  goToTask(result: ProcessResultI, task: TaskI) {
    const model_task = (this.process?.tasks || []).filter((v) => v.id === task.id)[0];
    //dispatch existence determine if task is accessible even if that is not in draft or complete status
    if (model_task.dispatch){
      if (task.status.key === TaskStatus.draft || task.status.key === TaskStatus.complete){
        const url = `/process/${this.process?.id}/process-results/${result.id}/${task.id}`
        this.router.navigate([url]);
      }
    } else {
      const url = `/process/${this.process?.id}/process-results/${result.id}/${task.id}`
      this.router.navigate([url]);
    }
  }

  // Security Action. Open right bar and show security tab
  showProcessResultDetails(event: any, result: ProcessResultI | null){
    event.stopPropagation();
    this.store.dispatch(setResultDetailsTab({tab: this.detailTabs.info}));
    this.store.dispatch(setDetailProcessResult({result: result}));
    this.store.dispatch(setShowRightBar({status: true}));
  }

  // Security Action. Open right bar and show security tab
  showProcessResultSecurity(event: any, result: ProcessResultI | null){
    event.stopPropagation();
    this.store.dispatch(setResultDetailsTab({tab: this.detailTabs.security}));
    this.store.dispatch(setDetailProcessResult({result: result}));
    this.store.dispatch(setShowRightBar({status: true}));
  }


  // Edit process result action
  editResult(event: any, index: number) {
    event.stopPropagation();
    const result = this.originalResultList[index];

    //Get first draft task
    const tasks = result.tasks.filter((task) => {
      return task.type !== TaskType.GROUP
    })

    // Calc url to first draft task
    if (tasks.length > 0) {
      const url = `/process/${this.process?.id}/process-results/${result.id}/${tasks[0].id}`
      this.router.navigate([url]);
    }
  }

  // Edit process result action
  showResult(event: any, index: number) {
    event.stopPropagation();
    const result = this.originalResultList[index];

    //Get first draft task
    const tasks = result.tasks.filter((task) => {
      return task.type !== TaskType.GROUP
    })

    // Calc url to first draft task
    if (tasks.length > 0) {
      const url = `/process/${this.process?.id}/process-results/${result.id}/${tasks[0].id}`
      this.router.navigate([url]);
    }
  }


  //Remove process result action
  remove(event: any, result: ProcessResultI) {
    event.stopPropagation();

    const initialState: ModalOptions = {
      initialState: {
        language: this.language,
        message: this.language?.deleteMessage || 'Do you really want to delete this record? This process cannot be undone.',
        item: result
      },
      backdrop: "static",
      keyboard: false,
      class: "modal-sm"
    };
    this.bsModalRef = this.modalService.show(RemoveModalComponent, initialState);
    this.bsModalRef.content.action.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: ProcessResultI) => {
      if (value && this.process) {
        const {bookmarked:_, process_status:__, tasks:___, ...new_values} = this.processResultFilter;
        const dynamicFilterStr = Object.keys(new_values).reduce((acc: string, curr: string) => {
          if (new_values[curr]) return acc + `$${curr}=${new_values[curr]}`;
          return acc;
        }, "").slice(1)


        let data: any = {
          limit: this.localStorageService.getItemPerPage() || initialProcessResultPaginator.limit,
          page: String(Math.min(
            this.resultPaginator.page,
            Math.floor(
              (+this.resultPaginator.count-1)/(+this.localStorageService.getItemPerPage() || initialProcessResultPaginator.limit)) +
                  (+this.resultPaginator.count - 1)%(+(+this.localStorageService.getItemPerPage() || initialProcessResultPaginator.limit)) > 0 ? 1 : 0
          ))
        }

        if (this.processResultFilter.bookmarked){
          data["bookmarked"] = this.processResultFilter.bookmarked
        }
        if (`${this.processResultFilter.process_status}`){
          data["process_status"] = `${this.processResultFilter.process_status}`
        }
        if (`${this.processResultFilter.tasks}`){
          data["process_tasks"] = `${this.processResultFilter.tasks}`
        }
        if (dynamicFilterStr){
          data["filter"] = dynamicFilterStr
        }
        if (this.processResultGlobalSearch) {
          data["search"] = this.processResultGlobalSearch;
        }

        this.store.dispatch(setLoadingResult({status: true}));
        this.store.dispatch(deleteProcessResult({
          processId: this.process.id,
          resultId: value.id,
          data: data
        }))
      }
    })
  }

  // Show row details, select row
  setDetailsProcessResult(event: any, result: ProcessResultI | null, index: number){
    event.stopPropagation();
    if (this.process && result){
      this.resultListDropdown[index] = !this.resultListDropdown[index];
      this.store.dispatch(setDetailProcessResult({result: result}));
      this.store.dispatch(setLoadingDetailProcessResult({status: true}));
      this.store.dispatch(loadDetailProcessResult({
        processId: this.process.id,
        resultId: result.id
      }))
    }

  }

  toggleSelection(id: string, event: any) {
    if (event.target.checked) {
      this.selectedIds.add(id);
    } else {
      this.selectedIds.delete(id);
    }
  }


}
