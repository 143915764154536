<div class="d-flex align-items-center justify-content-start">
  <span
    *ngIf="value === bookmarkedType.active"
    (click)="toggleValue()"
    class="material-symbols-sharp hover:material-symbols-sharp text-title text-primary-color pointer p-1 ps-0"
    tooltip="{{language?.remove || 'remove' | titlecase }} {{language?.bookmark || 'bookmark' | titlecase }}"
    [adaptivePosition]="false"
    containerClass="text-mini fw-bold m-1"
  >
    bookmark
  </span>
  <span
    *ngIf="value === bookmarkedType.inactive || value === false"
    (click)="toggleValue()"
    class="material-symbols-outlined hover:material-symbols-outlined text-title text-primary-color pointer p-1 ps-0"
    tooltip="{{language?.add || 'add' | titlecase }} {{language?.bookmark || 'bookmark' | titlecase }}"
    container="body"
    [adaptivePosition]="false"
    containerClass="text-mini fw-bold m-1"
  >
    bookmark
  </span>
</div>

